import React, { useState, useEffect, useRef } from "react";
import { useQuery, useMutation } from '@apollo/react-hooks'
import { chatMutations } from '../../graphql/mutations'
import { chatQueries } from '../../graphql/queries'
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import Spinner from "ui-components/lib/components/Spinner";
import useAlertStack from 'ui-components/lib/hooks/useAlertStack'
import { formatISOTimestampToDate } from "../../utils";
import { ReactComponent as SendMessageIcon } from "../../images/icons/sendMessageIcon.svg";
import { ReactComponent as ChatBg } from "../../assets/img/DemandsV2/chat-bg.svg";
import "./ChatDemandFixedV3.css";
import {Col, Row} from "react-bootstrap";
import Dropzone from "../Dropzone";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperclip } from '@fortawesome/free-solid-svg-icons'

const ChatDemandFixedV3 = (props) =>{
  const { showMessage } = useAlertStack()
  const [message, setMessage] = useState("")
  const [chatMessagesData, setChatMessagesData] = useState([])
  const [files,setFiles] = useState([]);
  const [loadingFiles, setLoadingFiles] = useState(false)
  const refConversasion = useRef(null);

  useEffect(() => {
    if (refConversasion.current) {
      refConversasion.current.scrollTop = refConversasion.current.scrollHeight;
    }
  }, [chatMessagesData]);

  const {
    openModal,
    demandId,
    demandLawyer,
    subscription
  } = props;


  const { loading: chatLoading, data: chatData, refetch: chatRefetch } = useQuery(chatQueries.GET_CHAT_MESSAGES, {
    variables: {
      demandId: demandId,
      subscription: subscription
    },
  })


  const [sendChatMessage, { loading: sendChatMessageLoading }] = useMutation(chatMutations.SEND_CHAT_MESSAGES, {
    variables: {
      demandId: demandId ? demandId : null,
      lawyerId: demandLawyer ? demandLawyer._id : null,
      message: message,
      subscription: subscription,
      files:files
    },
    onCompleted(response) {
      setMessage("")
      setFiles([])
      chatRefetch()
    },
    onError(error) {
      showMessage({
        title: 'Erro ao enviar a mensagem',
        message: 'Tente novamente mais tarde.',
        color: 'danger',
        position: 'bottom-left',
        time: 4000,
      })
    },
  })

  const handleChangeMessage = (event) => {
    setMessage(event.target.value)
  }

  const handleSendMessage = () => {
    if(message){
      sendChatMessage()
    }
  }

  const handleSendMessageByKeyPress = (event) => {
    if(event.key === 'Enter' && message){
      sendChatMessage()
    }
  }

  function formatDate(timestamp) {
    const date = new Date(timestamp);
    const day = date.toISOString().split('T')[0]; // YYYY-MM-DD
    const hour = date.getHours(); // Hora sem zero à esquerda
    const minute = date.getMinutes().toString().padStart(2, '0'); // Minuto com dois dígitos
    const hourMinute = `${hour}:${minute}`; // Concatenar hora e minuto no formato H:mm
    return { day, hourMinute };
  }

  useEffect(() => {
    if(chatData?.getChatMessages) {
      const chatMessages = chatData?.getChatMessages?.reduce((grouped, message) => {
        const { day, hourMinute } = formatDate(message.createdAt || message.created_at);
      
        if (!grouped[day]) {
          grouped[day] = {};
        }
      
        if (!grouped[day][hourMinute]) {
          grouped[day][hourMinute] = [];
        }
      
        grouped[day][hourMinute].push(message);
        
        return grouped;
      }, {});

      setChatMessagesData(chatMessages)
    }
  }, [chatData]); // eslint-disable-line

  useEffect(() => {
    if(openModal){
      chatRefetch()
    }
  },[openModal]) // eslint-disable-line

  function setFilesHandler(file){
    setLoadingFiles(false)
    let actualFilesList = files;

    actualFilesList.push(file);
    let actualFilesListFiltered = file.map(({ fileName: name, randomFileName, mimeType, fileExtension: extension, fileSize: size }) => ({
      attachment: '',
      name,
      randomFileName,
      mimeType,
      extension,
      size,
    }))
    setFiles(actualFilesListFiltered);
  }

  return (
    <Box className="chatv3">
      <Box className="list-chat" ref={refConversasion}>
        <div>
          {Object.keys(chatMessagesData).map((day) => (
            <div key={day}>
              <div className="message-date">
                <span>{formatISOTimestampToDate(day).formattedDate}</span>
              </div>
              
              {Object.keys(chatMessagesData[day]).map((hourMinute) => (
                <>  
                  {chatMessagesData[day][hourMinute].map((message, counter_msg) => (
                    <div key={message._id} className={message.sender === 'client' ? 'message-client' : 'message-lawyer'}>
                      <span className="message">{message.message}</span>
                      {message.files && message.files.length > 0 && message.files.map((item,counter) => {
                        return (
                          <div>
                            <button type="button" key={counter} className={"message-attatch-v3"} onClick={()=>window.open(item.fileURL,'_blank')} style={{marginRight:'1px'}}>
                              <FontAwesomeIcon icon={faPaperclip} /> {item.name}
                            </button>
                          </div>
                        )
                      })}
                      {(counter_msg + 1) === chatMessagesData[day][hourMinute].length && (
                        <div key={`${message._id}${hourMinute}`} className={message.sender === 'client' ? 'message-client' : 'message-lawyer'}>
                          <div className="hour">{hourMinute}</div>
                        </div>
                      )}
                    </div>
                  ))}
                </>
              ))}
            </div>
          ))}
          {(!chatMessagesData || Object.keys(chatMessagesData).length === 0) && (
            <div style={{textAlign: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
              <Row>
                <h4><strong>Envie mensagens, anexos e midias</strong></h4>
              </Row>
              <Row style={{margin: 'auto'}}>
                <Box>
                  <Col>
                    <Row style={{flexDirection: 'column'}}>
                      <ChatBg/>
                    </Row>
                  </Col>
                </Box>
              </Row>
            </div>
          )}
        </div>
      </Box>
      <Box className="send-message">
        <FormControl variant="outlined" style={{width: '100%', height: '100%'}}>
          <OutlinedInput
            sx={{height: '100%', width: '100%', borderRadius: "8px"}}
            id="outlined-adornment-message-v3"
            value={message}
            onChange={handleChangeMessage}
            onKeyPress={handleSendMessageByKeyPress}
            endAdornment={
              <InputAdornment position="end">
                {chatLoading || sendChatMessageLoading || loadingFiles? (
                  <Spinner color="primary" />
                ) : (
                    <>
                      <IconButton
                          onClick={(e)=>{
                            e.preventDefault();
                            let fileHandler = document.getElementById('file-demand');
                            fileHandler.click();
                          }}
                      >
                        <FontAwesomeIcon icon={faPaperclip} />
                      </IconButton>
                      <IconButton
                        onClick={handleSendMessage}
                      >
                        <SendMessageIcon />
                      </IconButton>
                    </>
                )}
              </InputAdornment>
            }
            placeholder="Escreva uma mensagem"
            inputProps={{
              'aria-label': 'message',
            }}
          />
        </FormControl>
        <Row>
          <div>
            {files.length > 0 && files.map((file,counter)=>{
              return (
                <button key={counter} disabled className={"message-attatch"} onClick={()=>window.open(file.fileURL,'_blank')} style={{marginRight:'1px'}}>{file.name}</button>
              )
            })}
          </div>
        </Row>
      </Box>
      <Dropzone  onLoad={setLoadingFiles} onComplete={(file)=>setFilesHandler(file)}/>
    </Box>
  );
}

export default ChatDemandFixedV3;
